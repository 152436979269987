<script setup lang="ts">

</script>

<template>
    <footer class="p-16 text-center text-gray-500 bg-white mt-auto">
        Copyright - Agence nationale des transports terrestres (AnaTT)
    </footer>
</template>

<style scoped>

</style>